<template>
  <div class="box noprint links-box">
    <div class="">
      <ul class="links-list">
        <li v-for="item in webLinks" :key="item.id">
          <a @click="linkClickFunction(item.value)"> - {{ item.label }} </a>
          <br />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      webLinks: null
    };
  },
  methods: {
    loadWebLinks() {
      this.webLinks = this.$store.state.webLinks;
    },
    linkClickFunction(value) {
      window.open(value);
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("getWebLinks");
      this.loadWebLinks();
    } catch (e) {
      this.$store.commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: `${e}`,
        notificationType: "error"
      });
    }
  }
};
</script>
<style lang="scss">
@media print {
  .noprint {
    display: none;
  }
}
.links-box {
  height: 100%;
}
.links-list {
  line-height: 200%;
}
</style>
